body {
  overflow: hidden;
  -ms-overflow-x: hidden;
  -ms-overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font: 800 calc(1.5em + 1.5vw) Poppins, Arial, sans-serif !important;
}

.container {
  padding: 20px 50px;
}

//margin
.m-auto {
  margin: auto;
}

.m-1 {
  margin: 10px;
}

.m-2 {
  margin: 20px;
}

.m-3 {
  margin: 30px;
}

.m-4 {
  margin: 40px;
}

.m-5 {
  margin: 50px;
}

//margin top
.mt-auto {
  margin-top: auto;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

//margin left
.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

//margin bottom
.mb-auto {
  margin-bottom: auto;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

//margin right
.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

//padding
.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.p-3 {
  padding: 30px;
}

.p-4 {
  padding: 40px;
}

.p-5 {
  padding: 50px;
}

//padding top
.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

//padding left
.pl-1 {
  padding-left: 10px;
}

.pl-2 {
  padding-left: 20px;
}

.pl-3 {
  padding-left: 30px;
}

.pl-4 {
  padding-left: 40px;
}

.pl-5 {
  padding-left: 50px;
}

//padding bottom
.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

//padding right
.pr-1 {
  padding-right: 10px;
}

.pr-2 {
  padding-right: 20px;
}

.pr-3 {
  padding-right: 30px;
}

.pr-4 {
  padding-right: 40px;
}

.pr-5 {
  padding-right: 50px;
}

.pointer {
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  @media (max-width: 728px) {
    width: 2px;
    height: 2px;
  }
}

body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

body::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border: 0 none #ffffff;
  border-radius: 50px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

body::-webkit-scrollbar-thumb:active {
  background: #999999;
}

body::-webkit-scrollbar-track {
  background: #d6d6d6;
  border: 0 none #ffffff;
  border-radius: 50px;
}

body::-webkit-scrollbar-track:hover {
  background: #d6d6d6;
}

body::-webkit-scrollbar-track:active {
  background: #d6d6d6;
}

body::-webkit-scrollbar-corner {
  background: transparent;
}

.not-scroll {
  scrollbar-width: none;
}

.not-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.beauty-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  @media (max-width: 728px) {
    width: 2px;
    height: 2px;
  }
}

.beauty-scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.beauty-scroll::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border: 0 none #ffffff;
  border-radius: 50px;
}

.beauty-scroll::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

.beauty-scroll::-webkit-scrollbar-thumb:active {
  background: #999999;
}

.beauty-scroll::-webkit-scrollbar-track {
  background: #d6d6d6;
  border: 0 none #ffffff;
  border-radius: 50px;
}

.beauty-scroll::-webkit-scrollbar-track:hover {
  background: #d6d6d6;
}

.beauty-scroll::-webkit-scrollbar-track:active {
  background: #d6d6d6;
}

.beauty-scroll::-webkit-scrollbar-corner {
  background: transparent;
}

//Antd config
.ant-table-tbody {
  background: white !important;
}

.ant-tag {
  border-radius: $border-radius + 8px;
}

.ant-timeline-item-content {
  min-height: auto !important;
}

.ant-btn {
  border-radius: $border-radius;
}

.ant-dropdown-menu {
  border-radius: $border-radius;
}

.ant-menu-item {
  border-radius: $border-radius;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected
  .ant-menu-submenu-title {
  border-radius: $border-radius;
  background: rgba(233, 235, 240, 0.5) !important;
  color: $text-secondary !important;
  font-weight: 700;

  .ant-menu-submenu-arrow {
    color: $text-secondary !important;

    &:hover {
      color: black !important;
    }
  }
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  .ant-menu-item {
    height: 35px !important;
    line-height: 35px !important;
    margin-left: 25px !important;
    width: calc(100% - 25px) !important;
    padding-left: 25px !important;
  }
}

.ant-menu.ant-menu-horizontal {
  border-bottom: none;

  .ant-menu-item {
    padding: 0 5px;
    margin: 0 5px !important;
    border-bottom: none;
    border-radius: $border-radius $border-radius 0 0;
    transition: none !important;

    &:hover {
      border-bottom: none !important;
    }

    &.ant-menu-item-selected {
      background: #f1f1f1 !important;
    }
  }
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  border-radius: $border-radius;
}

.ant-input,
.ant-input-number,
.ant-select,
.ant-select-selector,
.ant-picker,
.custom-input {
  border-radius: $border-radius !important;

  div {
    border-radius: $border-radius !important;
  }

  .ant-input-number-handler-up {
    border-top-right-radius: $border-radius !important;
  }

  .ant-input-number-handler-down {
    border-bottom-right-radius: $border-radius !important;
  }
}

.ant-input-affix-wrapper {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.ant-input-group-addon,
.ant-btn.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.table-responsive {
  overflow: hidden;

  .ant-table-content {
    background: white;
    max-width: 100%;
    overflow: auto;
  }
}

.ant-card {
  border-radius: $border-radius;
}

.ant-btn.ant-btn-icon-only.ant-input-search-button {
  background: $primary;

  span {
    color: white;
    transition: color 0.3s;
  }

  &:hover {
    box-shadow: 0 0 0 2px rgba(238, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(238, 0, 0, 0.2);
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  cursor: pointer;
  //background: rgba($primary, .05);
}

//Finish Antd
.size-10 {
  font-size: 10px;
}

.size-12 {
  font-size: 12px;
}

.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

.size-20 {
  font-size: 20px;
}

.text-primary {
  color: $primary;
}

.text-success {
  color: $success;
}

.text-secondary-2 {
  color: $secondary;
}

.text-secondary {
  color: $text-secondary !important;
}

.text-white {
  color: white !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-danger {
  color: $danger;
}

.view-desktop {
  display: block;
  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background: white;
    padding-top: 5px;
    padding-right: 10px;
    position: fixed;
    border-top-left-radius: $border-radius + 5px;
    border-top-right-radius: $border-radius + 5px;
    -webkit-box-shadow: 0px -2px 9px 0 $shadow-color;
    box-shadow: 0px -2px 9px 0 $shadow-color;
    z-index: 2;
  }
}

.btn-actions-header {
  position: absolute;
  top: 10px;
  right: 10px;

  @media (max-width: 728px) {
    position: relative;
    margin-bottom: 20px;
    text-align: right;
    button {
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
      margin-right: 5px;
    }
  }
}

//ckeditor config
.cke_inner,
.cke_chrome {
  border-radius: $border-radius !important;
  border: 1px solid rgba($primary, 0.3) !important;
}

.cke_top {
  border-top-right-radius: $border-radius !important;
  border-top-left-radius: $border-radius !important;
}

.cke_bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.cke_toolbar {
  margin-top: 3px !important;
}

.cke_bottom {
  background: $primary !important;
}

.cke_notification {
  margin-top: 10px;
  position: relative !important;
  width: 325px !important;
  max-width: calc(100% - 30px) !important;
  border-radius: 2px !important;
  background: white !important;
  color: #282c34 !important;
  padding: 16px 24px !important;
  border: none !important;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  .cke_notification_close {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

.cke_button {
  cursor: pointer !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  border: none !important;
  padding: 4px 6px !important;
}

.cke_button_on {
  border: none !important;
  background-color: rgb(232, 240, 254) !important;
  color: rgb(26, 115, 232) !important;
}

.cke_1 a.cke_button_off:hover,
.cke_1 a.cke_button_off:focus,
.cke_1 a.cke_button_off:active {
  background-color: $dark-light !important;
  border: none !important;
}

.cke_contents {
  min-height: 250px !important;
}

//Finish CK Editor

.react-tel-input .form-control {
  max-width: 100% !important;
}

.react-tel-input {
  .flag-dropdown {
    background: transparent !important;
    border: none !important;
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;

    .selected-flag {
      border: none !important;
      background: transparent !important;
    }

    .selected-flag.open {
      border: none !important;
      background: transparent !important;

      &:hover,
      :focus,
      :active {
        border: none !important;
        background: transparent !important;
      }
    }
  }

  .form-control {
    height: 40px !important;
    border-radius: $border-radius !important;

    &:focus {
      border-color: $primary;
    }
  }
}

.btn-secondary {
  background: $secondary;
  color: white;

  &:hover {
    background: #4285f4;
    border-color: #4285f4;
    color: white;
  }

  &:focus {
    background: #4285f4;
    border-color: #4285f4;
    color: white;
  }
}

.btn-purple {
  background: $alerts;
  color: white;

  &:hover {
    background: rgba($alerts, 0.8);
    border-color: rgba($alerts, 0.8);
    color: white;
  }

  &:focus {
    background: rgba($alerts, 0.8);
    border-color: rgba($alerts, 0.8);
    color: white;
  }
}

.btn-secondary-text {
  color: $success;

  &:hover {
    color: $success;
    border-color: $success;
  }

  &:focus {
    color: $success;
    border-color: $success;
  }
}

.btn-purple-text {
  color: $alerts;
  border-color: rgba($alerts, 0.8);

  &:hover {
    color: rgba($alerts, 0.8);
    border-color: rgba($alerts, 0.8);
  }

  &:focus {
    color: rgba($alerts, 0.8);
    border-color: rgba($alerts, 0.8);
  }
}

.custom-plugin-editor-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
}

.editor-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-plugin-editor-dropdown {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}

.custom-plugin-editor-editor {
  width: 675px;
  max-width: 100%;
  order: 1;
}

.custom-plugin-editor-buttons {
  margin-top: 0 !important;
  height: fit-content;
  margin-left: 10px;
  display: flex !important;
  flex-wrap: wrap;
  max-width: 250px;
  order: 2;
}

@media (max-width: 768px) {
  .custom-plugin-editor-buttons {
    max-width: 100%;
    margin-bottom: 10px;
    order: 1;
  }
  .custom-plugin-editor-editor {
    width: 100%;
    order: 2;
  }
  .custom-plugin-editor-dropdown {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }
}

.custom-editor-tag {
  padding: 3px 5px;
  background: rgba(100, 100, 100, 0.1);
  border-radius: $border-radius;
  font-weight: bold;
}

.number-list {
  display: flex;

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 100%;
    padding: 10px;
    border: 1px solid $primary;
    color: $primary;
  }

  span {
    margin-left: 20px;
    font-size: 22px;
    font-weight: 500;
    color: $primary;
  }
}

.bg-content {
  background: $dark-light;
}

.rccs {
  max-width: 100%;
}

.rccs__card {
  max-width: 100%;
}

.text-link {
  color: $secondary;

  &:hover {
    color: darken($secondary, 25%);
  }

  &:focus {
    color: darken($secondary, 25%);
  }
}

.d-flex {
  display: flex;
}

.icon-hover {
  cursor: pointer;
  transition: all 250ms;
}

.icon-hover:hover {
  color: $cian !important;
  transition: all 250ms;
}

.btn-primary {
  border-color: $primary;
  color: $primary;
}

.btn-alert {
  border-color: $alerts;
  color: $alerts;
  transition: all 250ms;

  &:hover {
    background: $alerts;
    color: #ffffff;
    border-color: $alerts;
    transition: all 250ms;
  }
}
